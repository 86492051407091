<template>
  <vx-card>
    <div class="vx-col w-full">
      <span>
        <b>Edit {{ title }}</b>
      </span>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            label="Name"
            v-validate="'required'"
            name="Name"
            v-model="data.name"
          />
          <span class="text-danger text-sm" v-show="errors.has('Name')">{{
            errors.first("Name")
          }}</span>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <!-- radio button -->
          <label class="vs-input--label">Status</label>
          <br />
          <br />
          <vs-radio v-model="data.status" vs-value="1">Active</vs-radio>
          &nbsp;&nbsp;&nbsp;
          <vs-radio v-model="data.status" vs-value="0">InActive</vs-radio>
          <span class="text-danger text-sm" v-show="errors.has('Status')">{{
            errors.first("Status")
          }}</span>
        </div>
      </div>
      <!-- select flyer -->
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <label class="vs-input--label">Select Flyer</label>
          <table class="table">
            <thead class="bg-blue-500 text-black">
              <tr>
                <th scope="col">Sequence</th>
                <th scope="col">Flyer ID</th>
                <th scope="col">Flyer Description</th>
                <th scope="col">Valid From</th>
                <th scope="col">Valid To</th>
                <th scope="col">Image</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data.flyers" :key="index">
                <th>
                  <vs-input
                    type="number"
                    v-validate="'required'"
                    min="1"
                    step="1"
                    v-model="item.sequence"
                  />
                </th>
                <td>
                  <multiselect
                    class="selectExample"
                    v-model="item.selected_flyer"
                    :options="optionFlyer"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="160"
                    :limit="4"
                    placeholder=" Type to search"
                    track-by="id"
                    label="name"
                    :disabled="false"
                    @select="onSelectFlyer($event, index)"
                  >
                    <template slot="singleLabel" slot-scope="dt">
                      <span class="option__desc">
                        <span class="option__title">{{ dt.option.name }}</span>
                      </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                      <div class="option__desc">
                        <span class="option__title">{{ dt.option.name }}</span>
                      </div>
                    </template>
                  </multiselect>
                </td>
                <td v-html="item.description" />
                <td>{{ item.valid_from }}</td>
                <td>{{ item.valid_to }}</td>
                <td><img :src="item.image" alt="" class="w-64" /></td>
                <td>
                  <!-- btn add -->
                  <vs-button
                    v-if="index == 0"
                    type="filled"
                    color="primary"
                    @click="addFlyer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </vs-button>
                  <!-- btn delete -->
                  <vs-button
                    v-else
                    type="filled"
                    color="danger"
                    @click="deleteFlyer(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </vs-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleClose"
            >Close</vs-button
          >
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  data() {
    return this.initialState();
  },
  filters: {
    // string to int
    toInt(value) {
      return parseInt(value);
    },
  },
  methods: {
    addFlyer() {
      this.isAddedFirst = false;
      this.data.flyers.push({
        sequence: "1",
        id: null,
        selected_flyer: null,
        description: "",
        valid_from: "",
        valid_to: "",
        image: "",
      });
    },
    async deleteFlyer(index) {
      this.data.flyers.splice(index, 1);
    },
    initialState() {
      return {
        title: "Flyer Allocation",
        isAddedFirst: true,
        data: {
          id: null,
          territory_id: null,
          territory_name: "",
          flyers: [
            {
              sequence: "1",
              id: null,
              selected_flyer: null,
              description: "",
              valid_from: "",
              valid_to: "",
              image: "",
            },
          ],
        },
        submitted: false,

        // select
        flyers: [],
        optionFlyer: [],
      };
    },
    onSelectFlyer(data, index) {
      this.data.flyers[index].id = data.id;
      this.data.flyers[index].description = data.description;
      this.data.flyers[index].valid_from = moment(data.valid_from).format(
        "YYYY-MM-DD"
      );
      this.data.flyers[index].valid_to = moment(data.valid_to).format(
        "YYYY-MM-DD"
      );
      this.data.flyers[index].image = data.image_url;
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          this.putData();
        }
      });
    },
    async paramData() {
      let flyers = await this.data.flyers.map((item) => {
        // check if id not null return it
        if (item.id) {
          return {
            id: item.id,
            sequence: item.sequence.toString(),
          };
        }
      });

      // remove null value
      flyers = flyers.filter((item) => item != null);
      const status = parseInt(this.data.status) == 1 ? true : false;

      let data = {
        id: this.data.id,
        name: this.data.name,
        status: status,
        territory_id: this.data.territory_id,
        flyers: flyers,
      };

      return JSON.stringify(data);
    },
    getData(id) {
      this.$http
        .get(`/api/sfa/v1/flyer-allocations/${id}`)
        .then((response) => {
          let data = response.data;
          this.data.id = data.id;
          this.data.name = data.name;
          this.data.status = data.status ? 1 : 0;
          this.data.territory_id = data.territory_id;
          for (let i = 0; i < data.flyers.length; i++) {
            this.data.flyers.push({
              sequence: data.flyers[i].sequence,
              id: data.flyers[i].id,
              selected_flyer: {
                id: data.flyers[i].id,
                name: data.flyers[i].description,
              },
              description: data.flyers[i].description,
              valid_from: moment(data.flyers[i].valid_from).format(
                "YYYY-MM-DD"
              ),
              valid_to: moment(data.flyers[i].valid_to).format("YYYY-MM-DD"),
              image: data.flyers[i].image_url,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async putData() {
      this.submitted = true;
      const data = await this.paramData();
      this.$http
        .post(`/api/sfa/v1/flyer-allocations`, data, {
          "Content-Type": "application/json",
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      // back to list
      this.$router.push({ name: "flyer" });
    },
    getOptionFlyers() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/flyers", {
          params: {
            order: "name",
            sort: "asc",
            length: 9999,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionFlyer = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Flyers option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  async mounted() {
    // get id from url
    let id = this.$route.params.id;
    await this.getData(id);
    await this.getOptionFlyers();
  },
  computed: {},
};
</script>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
